import React, { useContext, useEffect, useState } from 'react';
import './PlanList.scss';
import PlanListItem from './PlanListItem';
import { DataContext } from '../../utils/DashboardDataContext';
import Button from '../Common/Button';
import { HintButton } from '../Common/Hints';

const PlanList = () => {
    var { planData, planSortBy, servicePoint } = useContext(DataContext);
    const [plansToShow, setPlansToShow] = useState(10);
    planData = planData.filter(plan => plan.planId !== 'actual_costs');
    const planDataTopN = planData ? planData.slice(0, plansToShow) : [];
    const planDataBot5 = planData ? planData.slice(planData.length - 5, planData.length) : [];
    const currentPlan = planData.find(plan => plan.planId == 'current_plan');
    const currentPlanCost = currentPlan?.consumerCosts.total + (planSortBy === 'greenPower' ? currentPlan?.greenPower?.maxCost || 0 : 0);

    useEffect(() => {
        setPlansToShow(10);
    },[servicePoint])

    return (
        <div id="planList">
            <div className="planListItems">
                {
                    planDataTopN.map((plan, index) => {
                        if (plan.planId === 'current_plan') return;
                        const planCost = plan.consumerCosts.total + (planSortBy === 'greenPower' ? plan?.greenPower?.maxCost || 0 : 0);
                        const deltaDollars = planCost - currentPlanCost;
                        const deltaPercentage = (deltaDollars / currentPlanCost) * 100;
                        const greenPowerMin = plan.greenPower.minAvailable;
                        const greenPowerMax = plan.greenPower.maxAvailable;
                        const greenPowerMaxCost = plan.greenPower?.maxCost;
                        const isRedacted = plan.redacted ?? false;

                        return <PlanListItem planId={plan.planId} brand={plan.brand} brandName={plan.brandName} greenPowerMin={greenPowerMin} greenPowerMax={greenPowerMax} planName={plan.displayName} deltaDollars={deltaDollars} deltaPercentage={deltaPercentage} planCost={planCost} currentPlanCost={currentPlanCost} redacted={isRedacted} key={plan.planId} />
                    })
                }
                <div className='listFooter'>
                    <Button type="secondary loadMore" clickHandler={(event) => {  event.preventDefault(); setPlansToShow((plansToShow + 10) > planData.length ? planData.length : (plansToShow + 10)); }}>
                        {plansToShow}<span className='minorDetail'>/ {planData ? planData.length : '?'}</span> {plansToShow == planData.length ? '' : 'Show more'}
                    </Button>
                </div>
            </div>
            <div className="planListItems">
                <div className='planListGroupHeader'>
                    <h2 className="plansListGroupHeading">Most expensive<HintButton topic="expensivePlans" /></h2>
                    <span className='subline'>The 5 most expensive plans for you</span>
                </div>
                {
                    planDataBot5.map((plan, index) => {
                        if (plan.planId === 'current_plan') return;
                        const planCost = plan.consumerCosts.total;
                        const deltaDollars = planCost - currentPlanCost;
                        const deltaPercentage = (deltaDollars / currentPlanCost) * 100;
                        const greenPowerMin = plan.greenPower.minAvailable;
                        const greenPowerMax = plan.greenPower.maxAvailable;
                        return <PlanListItem planId={plan.planId} brand={plan.brand} brandName={plan.brandName} greenPowerMin={greenPowerMin} greenPowerMax={greenPowerMax} planName={plan.displayName} deltaDollars={deltaDollars} deltaPercentage={deltaPercentage} planCost={planCost} currentPlanCost={currentPlanCost} key={plan.planId} />
                    })
                }
                <div className='listFooter'>
                    <a className='button secondary' href="#tailoredSuggestions">
                        Back to top
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PlanList;