import React, { useContext, useEffect, useState } from 'react';
import { CommonDataContext } from '../../utils/CommonDataContext';
import './ProfileContent.scss';
import Button from '../Common/Button';

export const ProfileContent = () => {
    const { userProfile, authenticatedUser } = useContext(CommonDataContext)

    return <>
        <div className="content-tile primary profile-tile">
            <h1>Your profile</h1>

            <div className="profile-section personal-information">
                <h2>Account</h2>
                <div className="profile-section-content">
                    <div className="profile-section-content-part">
                        <label className='profile-label'>First name</label>
                        <span className='profile-value'>{authenticatedUser.attributes.given_name}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Last name</label>
                        <span className='profile-value'>{authenticatedUser.attributes.family_name}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Email</label>
                        <span className='profile-value'>{authenticatedUser.attributes.email}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>ID Source</label>
                        <span className='profile-value'>{authenticatedUser.attributes.identities[0].providerName}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className='profile-tile your-data content-tile'>
            <div className="profile-section">
                <h2>Data</h2>
                <div className="profile-section-content">
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Description</label>
                        <span className='profile-value'> </span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Provider</label>
                        <span className='profile-value'> </span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Last collected</label>
                        <span className='profile-value'> </span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Your consent</label>
                        <span className='profile-value'> </span>
                    </div>
                    <div className="profile-section-content-part">
                        <p>Your data has been securely collected and stored with your consent in accordance with the Australian Consumer Data Right regulation and the Australian Privacy laws. See <a href='#'>Automised Energy's Consumer Data Right Policy</a> for details.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='profile-tile personal-information content-tile'>
            <div className="profile-section">
                <h2>Danger zone</h2>
                <p>WARNING! These actions are irreversable. Be sure of what you are doing.</p>
                <div className='section-action'>
                    <Button href="#" label="Delete Automised Energy Account" type="secondary" className="danger" icon="report" />
                </div>
            </div>
        </div>
    </>
}