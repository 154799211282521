import React, { useContext, useState } from 'react';
import SiteMenuItems from './SiteMenuItems';
import SiteMenuItem from './SiteMenuItem';
import { PseudoUserProfileContext } from '../../utils/PseudoUserProfileContext';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';

import './SiteMenuNav.scss';

const SiteMenuNav = ({ closeMenuFn }) => {
    const { demoUserId, setDemoUserId, demoUserProfiles } = useContext(PseudoUserProfileContext);
    const { authenticatedUser, userProfile } = useContext(AuthContext);
    const [selectedItem, setSelectedItem] = useState(window.location.pathname.split('/')[1] || 'dashboard');
    const navigate = useNavigate();

    const setUser = (userId) => {
        console.log(`user ${userId} selected`);
        setDemoUserId(userId);
        return false;
    }

    const setSelectedItemHandler = (itemName) => {
        setSelectedItem(itemName);
        return true;
    }

    const mode = 'DEMO';

    return (
        <div id="siteMenuNav">
            <a href="#" id="sideMenuHider" onClick={closeMenuFn}>
                Hide the menu
            </a>
            <img id="siteMenuLogo" className="siteMenuLogo" src='/assets/images/product-logo.svg' alt='Automised logo' />
            <img id="siteMenuLogoMobile" className="siteMenuLogo" src='/assets/images/product-logo-mobile.svg' alt='Automised logo' />
            <SiteMenuItems>
                <SiteMenuItem href="/" image="home" selected={selectedItem === 'dashboard'} handleClick={(event) => { setSelectedItemHandler('dashboard'); }}>Dashboard</SiteMenuItem>
                <SiteMenuItem href="/plan" image="tickDone" selected={selectedItem === 'plan'} handleClick={(event) => { setSelectedItemHandler('plan'); }}>Plan</SiteMenuItem>
                <SiteMenuItem href="/bills" image="bills" selected={selectedItem === 'bills'} handleClick={(event) => { setSelectedItemHandler('bills'); }}>Bills</SiteMenuItem>
            </SiteMenuItems>
        </div>
    );
};

export default SiteMenuNav;