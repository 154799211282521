import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const gstMultiplyer = 1.1;

export const formatCentsAsDollarsAddGst = (centsExGst, round = false) => {
    return formatAsString(cents2Dollars(centsExGst * gstMultiplyer), round);
}

export const formatCentsAsDollars = (cents, round = false) => {
    return formatAsString(cents2Dollars(cents), round);
}

export const formatCentsAddGst = (centsExGst, round = false) => {
    return formatAsString((centsExGst * gstMultiplyer), round);
}

export const formatCents = (cents, round = false) => {
    return formatAsString(cents);
}

function formatAsString(amount, round = false) {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, { maximumFractionDigits: round ? 0 : 2 }).format(amount);
}

function cents2Dollars(cents) {
    return cents / 100;
}

export function formatDate(dateString) { //Format YYYY-MM-DD dates into DD Mon YYYY dates
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateParts = dateString.split("-"); // Splits the date string into [YYYY, MM, DD]

    // Convert the month from MM format (01-12) to Mon format (Jan-Dec)
    const monthIndex = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert month from 1-12 to 0-11
    const monthName = months[monthIndex];

    // Format the date as DD Mon YYYY
    const formattedDate = `${parseInt(dateParts[2], 10)} ${monthName} ${dateParts[0]}`;

    return formattedDate;
}


/**
 * Parses a time string and returns a dayjs object.
 * This function handles various time formats including ISO 8601 with timezone offsets,
 * simple time strings with colons, and legacy time formats without colons.
 * 
 * @param {string} timeStr - The time string to parse.
 * @returns {Object} - A dayjs object representing the parsed time.
 * @throws Will throw an error if the time format is invalid.
 */
export function parseTariffWindowEdgeTime(timeStr) {
    const placeholderDate = '1970-01-01'; // Placeholder date to form a complete datetime string
    let date;
  
    if (timeStr.includes(':') && (timeStr.includes('+') || timeStr.endsWith('Z'))) {
      // If the time string includes a colon and either a timezone offset or ends with 'Z' (UTC)
      // Parse the time string as an ISO 8601 format with timezone handling
      date = dayjs(`${placeholderDate}T${timeStr}`).tz(dayjs.tz.guess());
    } else if (timeStr.includes(':')) {
      // If the time string includes a colon but no timezone information
      // Parse it as a simple time format with colons
      date = dayjs(`${placeholderDate}T${timeStr}`);
    } else {
      // Handle legacy format (e.g., "2030" or "20")
      // Extract hours and minutes manually
      const hours = parseInt(timeStr.substring(0, timeStr.length - 2), 10) || 0;
      const minutes = timeStr.length > 2 ? parseInt(timeStr.substring(timeStr.length - 2), 10) : (parseInt(timeStr, 10) || 0);
      // Create a dayjs object with the parsed hours and minutes
      date = dayjs().hour(hours).minute(minutes);
    }
  
    // If the date is not valid, throw an error
    if (!date.isValid()) {
      throw new Error(`Could not parse tariff window edge time of ${timeStr}. Invalid time format`);
    }
  
    return date; // Return the parsed dayjs object
  }