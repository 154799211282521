import React, { useEffect } from 'react';

export const StripePricingTableForNewCustomer = ({ email, referenceId }) => {
    return <StripePricingTable email={email} referenceId={referenceId} />
}

export const StripePricingTableForExistingCustomer = ({ clientSecret, referenceId }) => {
    return <StripePricingTable clientSecret={clientSecret} referenceId={referenceId} />
}

const StripePricingTable = ({ email, referenceId, clientSecret }) => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;

        // Append the script to the document body
        document.body.appendChild(script);

        // Cleanup the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <stripe-pricing-table
                pricing-table-id="prctbl_1PLexd05dvtv8IqIUj8ZkrNa"
                publishable-key="pk_test_51PKf0W05dvtv8IqIk85nwxXW6WmeRUlHF1U64TAJG7SvGLMNk4JE277uKkQxy6mQsC1YLSGltnPISz1AHmhvE3vp00fva9mYxk"
                client-reference-id={referenceId}
                {...(clientSecret 
                      ? { 'customer-session-client-secret': clientSecret } 
                      : { 'customer-email': {email} }
                )}
            >
            </stripe-pricing-table>
        </div>
    );
};