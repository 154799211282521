import React, { useContext, useEffect, useState } from 'react';
import './HeroSummary.scss';
import { HintButton } from '../Common/Hints'
import '@rmwc/dialog/styles';
import { AccordionContext } from '../../utils/AccordionContext';
import { StarRating } from '../Common/StarRating';

const HeroSummary = ({ publicPlanDetails }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);

    const hasEligibility = publicPlanDetails.electricityContract.eligibility && publicPlanDetails.electricityContract.eligibility.length > 0;

    const view = () => {
        return (
            <>
                <div id="heroHeader">
                    <div className='headerDetails'>
                        <div className='headerBrandAndLocation'>
                            <h1>{publicPlanDetails?.displayName}</h1>
                        </div>
                        <div className='headerPlanId'>
                            <span className='planIdLabel'>Plan ID:</span>{publicPlanDetails?.planId.slice(0, -4)}<HintButton topic="planId" />
                        </div>
                        {hasEligibility && <span className='eligibility-chip'>Has eligibility criteria</span>}
                        {publicPlanDetails?.obsoleted &&
                            <div className='headerPlanId'>
                                <span className='planIdLabel'>Warning: This plan became obsolete from {(new Date(publicPlanDetails?.obsoleted)).toDateString()}</span>
                            </div>
                        }
                    </div>
                    <div id="aboutBrand">
                        <span id="siteRetailer"><img className='brand-logo' src={`/assets/images/retailerLogos/${publicPlanDetails?.brand}.svg`} /> {publicPlanDetails?.brandName}</span>
                        <div className='detailItem' id='customerRatingDetail'>
                            <span className="detailLabel" id='customerRatingLabel'>Customer rating</span>
                            <span className='detailValue' id='customerRatingValue'>
                                {publicPlanDetails?.brandRating ?
                                    <>
                                        <StarRating id='brandRating' rating={publicPlanDetails?.brandRating} />
                                        <span className='ratingValue'>{publicPlanDetails?.brandRating}</span>
                                    </>
                                    :
                                    <span className='ratingValue unknown'>unknown</span>}
                                <HintButton topic="customerRating" />
                            </span>
                        </div>
                        <div className='detailItem' id='ecoRatingDetail'>
                            <span className="detailLabel" id='ecoRatingLabel'>Eco rating</span>
                            <span className='detailValue' id='ecoRatingValue'>
                                <StarRating id='ecoRating' rating={publicPlanDetails?.ecoRating} iconRated="eco" iconUnrated="eco" />
                                <span className='ratingValue'>{publicPlanDetails?.ecoRating}</span>
                                <HintButton topic="ecoRating" /></span>
                        </div>
                    </div>

                </div>
            </>);

    }

    return (
        <>
            <div id="planDetailsHeroSummary" className={`content-tile primary {dataIsLoading ? 'skeleton' : ''}`}>
                {view()}
            </div>
        </>
    );
};


export default HeroSummary;