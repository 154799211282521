import React, { useContext, useEffect, useState } from 'react';
import '../PlanDetails/GreenPower.scss';
import { formatCents, formatCentsAsDollars } from '../../utils/utils';
import Icon from '../Common/Icon';
import { SessionContext } from '../../utils/SessionContext';
import { HintButton } from '../Common/Hints';
import { ToggleGroup, ToggleOption } from '../Common/ToggleGroup';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';

const GreenPower = ({ planDetails }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    if (!planDetails) return;

    let greenPower;

    //greenPower = planDetails.greenPowerCharges;
    let greenPowerChargesAr = planDetails.electricityContract?.greenPowerCharges;
    if (!greenPowerChargesAr || greenPowerChargesAr.length < 1) {
        greenPowerChargesAr = undefined;
    }
    let maxAvailable = 0;
    const greenPowerOptionsAr = greenPowerChargesAr?.map((gpCharge, index) => {
        if (gpCharge.scheme === 'GREENPOWER') {
            const type = gpCharge.type === 'FIXED_PER_UNIT' ? "per KWh" : "per day";
            let optionsAr = gpCharge.tiers.map((tier, index) => {
                if (parseInt(tier.percentGreen) > maxAvailable) maxAvailable = parseInt(tier.percentGreen);
                return {
                    type: type,
                    amount: tier.amount,
                    max: tier.percentGreen
                }
            })
            return optionsAr;
        }
    })
    greenPower = {
        minAvailable: planDetails.greenPower?.minAvailable ?? 0,
        maxAvailable: maxAvailable,
        intrinsicPercent: parseInt(planDetails.electricityContract.intrinsicGreenPower?.greenPercentage ?? 0),
        optionalPercentTiers: greenPowerOptionsAr ? greenPowerOptionsAr[0] : 0, //TODO: deal with multiple greenPowerOptionsAr elements, not just take the first one. Need an example where there are more than one elements, though...
    }
    /*
    {"minAvailable":0,"maxAvailable":1,"intrinsic":0,"optional":[{"type":"perKWh","amount":0.7000000000000001,"max":0.25},{"type":"perKWh","amount":1.4000000000000001,"max":0.5},{"type":"perKWh","amount":2.8000000000000003,"max":1}],"maxCost":14120.292666666648}
    */

    console.debug(`GreenPower object`, greenPower)

    if (!greenPower || !greenPower.optionalPercentTiers) return <p>There are no GreenPower options available for this plan</p>;


    return (
        <div className="breakdownGreenPower">
            {(greenPower && (greenPower.optionalPercentTiers.length > 0 || greenPower.intrinsicPercent > 0)) &&
                <div className='optionalGreenPower'>
                    <h3>
                        GreenPower {greenPower.optionalPercentTiers.length > 0 ? 'options' : 'included'} with this plan:
                    </h3>
                    <div className='greenPowerOptions'>
                        {greenPower.intrinsicPercent > 0 &&
                            <div className='greenPowerOption'>
                                <span className='optGreenPowerDesc'>
                                    <strong>{greenPower.intrinsicPercent * 100}%</strong> GreenPower complimentary
                                </span>
                            </div>
                        }

                        {greenPower.optionalPercentTiers.map((gpCharge, index) => {
                            return (
                                <ul>
                                    <li className='greenPowerOption' key={index}>
                                        <span className='optGreenPowerDesc'>
                                            <strong>{gpCharge.max * 100}%</strong> GreenPower for <strong>{formatCents(gpCharge.amount)}c</strong> {gpCharge.type}
                                        </span>
                                    </li>
                                </ul>
                            )
                        })}
                    </div>
                </div>
            }

        </div>
    );
};


export default GreenPower;