import React, { useContext, useEffect, useState } from 'react';
import { AccordionContext } from '../../utils/AccordionContext';

const Eligibility = ({ planDetails }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    let content;


    if (planDetails.electricityContract.eligibility) {
        content = planDetails.electricityContract.eligibility.map((eligibility, index) => {
            return (
                <div key={index}>
                    <h3>{eligibility.information}</h3>
                    <p>{eligibility.description}</p>
                </div>
            )
        })
    } else {
        content = <p>There is no eligibility criteria for this plan.</p>
    }


    return (
        <div className="breakdownEligibility">
            {content}
        </div>
    );
};


export default Eligibility;