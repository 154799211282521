import React, { useContext, useEffect, useState } from 'react';
import './HeroSummary.scss';
import Button from '../Common/Button';
import { ToggleGroup, ToggleOption } from '../Common/ToggleGroup';
import { HintButton } from '../Common/Hints'
import { PseudoUserProfileContext } from '../../utils/PseudoUserProfileContext';
import '@rmwc/dialog/styles';
import { SessionContext } from '../../utils/SessionContext';
import PlanCostComparisonChart from './PlanCostComparisonChart';
import { formatCentsAsDollars, formatCentsAsDollarsAddGst } from '../../utils/utils';
import Icon from '../Common/Icon';
import { AccordionContext } from '../../utils/AccordionContext';
import { useNavigate } from 'react-router-dom';

const HeroSummary = ({ comparisonPlan, currentPlanCosts, displayAddress, dataIsLoading, greenPower, costDivisor, setGranularity, eligibilityIsConfirmed }) => {
    const { demoUserId } = useContext(PseudoUserProfileContext);
    const { sessionContext, setSessionContext } = useContext(SessionContext);
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);

    const navigate = useNavigate();

    let comparisonPlanCosts = comparisonPlan.consumerCosts.total;
    let planCostsDelta = currentPlanCosts - comparisonPlanCosts;
    let deltaDirection = (planCostsDelta < 0) ? 'negative' : 'positive';
    const hasEligibility = comparisonPlan.electricityContract.eligibility && comparisonPlan.electricityContract.eligibility.length > 0;

    let granularCurrentPlanCosts = currentPlanCosts / costDivisor.value;
    let granularComparisonPlanCosts = comparisonPlanCosts / costDivisor.value;
    let granularPlanCostsDelta = planCostsDelta / costDivisor.value;
    let displayCurrentPlanCosts = formatCentsAsDollars(parseInt(granularCurrentPlanCosts), true);
    let displayComparisonPlanCosts = formatCentsAsDollars(parseInt(granularComparisonPlanCosts), true);
    let displayPlanCostsDelta = formatCentsAsDollars(parseInt(granularPlanCostsDelta), true);


    const costView = () => {
        return (
            <>
                <div id="heroHeader">
                    <div className='headerDetails'>
                        <div className='headerBrandAndLocation'>
                            <h1>{comparisonPlan?.displayName}</h1>
                            {hasEligibility && <span className='eligibility-chip'>Eligibility criteria</span>}
                            <div id="siteLocation"><span className='siteAddress' title={displayAddress}>{displayAddress}</span></div>
                            <Button clickHandler={() => { shareClicked(comparisonPlan?.planId)}} icon="share" type="share" />
                        </div>
                        <div className='headerPlanName'>
                            <img className='retailerLogo' src={`/assets/images/retailerLogos/${comparisonPlan?.brand}.svg`} alt={`${comparisonPlan?.brandName} logo`} />
                            <span className='planName'>{comparisonPlan?.brandName}</span>
                            <span className='greenPercentage'>{greenPower == 1 ? '100%' : '0%'}<HintButton topic="greenPower" /></span>
                        </div>
                        <div className='headerPlanId'>
                            <span className='planIdLabel'>Plan ID:</span>{comparisonPlan?.planId.slice(0, -4)}<HintButton topic="planId" />
                        </div>
                    </div>
                </div>

                <div id="heroInfo">
                    <PlanCostComparisonChart valuePrefix="$" item2="This plan" item1="Your plan" price2={granularComparisonPlanCosts / 100} price1={granularCurrentPlanCosts / 100} />
                    <div id="heroDetails">
                        <h2>Cost comparison</h2>
                        <div className='detailItem'>
                            <span className='detailLabel'>Your plan</span>
                            <span className={`detailValue`}>${displayCurrentPlanCosts}</span><HintButton topic="yourPlanCosts" />
                        </div>
                        <div className='detailItem'>
                            <span className='detailLabel'>This plan</span>
                            <span className="detailValue">${displayComparisonPlanCosts}</span><HintButton topic="thisPlanCosts" />
                        </div>
                        <hr />
                        <div className='detailItem'>
                            <span className='detailLabel total'>Total savings</span>
                            <span className={`detailValue ${deltaDirection == 'positive' ? 'improvementIndicator' : 'deteriorationIndicator'}`}>${displayPlanCostsDelta}</span><HintButton topic="comparedSavings" />
                        </div>
                        <span className='disclaimer-note'>* GST included where applicable</span>
                        <div className='inlineCtaGroup'>
                            {eligibilityIsConfirmed ?
                                <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-now" clickHandler={() => setSelectedItem('switch-now')} />
                                :
                                <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                            }
                            <Button type="secondary" id="betterPlans" label="See plan details" icon="filter" href="#planDetailsHeading" clickHandler={() => console.log('Clicked')} />
                        </div>
                    </div>
                </div>
            </>);

    }

    useEffect(() => {
        console.log(`demoUserId: ${demoUserId}`)
        console.log(`sessionContext: ${JSON.stringify(sessionContext)}`)
        console.debug(`costDivisor: ${JSON.stringify(costDivisor)}`)
    }, []);


    useEffect(() => {
        console.debug(`costDivisor: ${JSON.stringify(costDivisor)}`)
    }, [costDivisor]);


    const gotoDashboard = () => {
        navigate('/');
    }

    return (
        <>
            <div id="planDetailsHeroSummary" className={`content-tile primary {dataIsLoading ? 'skeleton' : ''}`}>
                <div className='backNav'>
                    <button onClick={gotoDashboard}><Icon name="arrow_back_long" descriptiveLabel="Go back" /> Go Back</button>
                </div>
                {costView()}
            </div>
            <ToggleGroup level="primary" className={dataIsLoading ? 'skeleton' : ''} clickHandler={(selectedValue) => setGranularity(selectedValue)} selectedToggleValue={costDivisor.name}>
                <ToggleOption text="Yearly" value="yearly" />
                <ToggleOption text="Quarterly" value="quarterly" />
                <ToggleOption text="Monthly" value="monthly" />
            </ToggleGroup>
        </>
    );
};

function shareClicked(planId) {
    const urlToShare = window.location.origin + '/plans/' + planId;

    if (navigator.share) {
        navigator.share({
            title: document.title,
            url: urlToShare
        })
        .then(() => console.log('Share successful'))
        .catch((error) => console.log('Error sharing:', error));
    }else {
        // If on a desktop, copy the link to the clipboard
        navigator.clipboard.writeText(urlToShare)
        .then(() => {
            alert('Link copied to clipboard');
        })
        .catch((error) => {
            console.error('Error copying to clipboard:', error);
        });
    }
}

export default HeroSummary;