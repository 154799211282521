import React, { useContext, useEffect, useState } from 'react';
import PageFooter from '../components/Common/PageFooter';
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate, useParams } from 'react-router-dom';
import HeroSummary from '../components/PublicPlanDetails/HeroSummary';
import PlanDetailsBreakdown from '../components/PublicPlanDetails/PlanDetailsBreakdown';
import { useLocation } from 'react-router-dom';
import { AccordionContext } from '../utils/AccordionContext';
import * as AutomisedApis from '../utils/automisedAPIs'
import { HintProvider } from '../utils/HintContext';
import NotificationBar from '../layouts/NotificationBar';
import PageBody from '../layouts/PageBody';
import { HintModal } from '../components/Common/Hints';
import './PublicPlanDetails.scss'


const PublicPlanDetails = () => {
    const { planId } = useParams();
    const [selectedItem, setSelectedItem] = useState(null);
    const [errorLoadingData, setErrorLoadingData] = useState(false);
    const [publicPlanDetailsAreLoading, setPublicPlanDetailsAreLoading] = useState(false);
    const [publicPlanDetails, setPublicPlanDetails] = useState(false);

    //Extract query params
    const location = useLocation();
    const queryString = location.search;
    const queryParams = new URLSearchParams(queryString);

    useEffect(() => {
        setPublicPlanDetailsAreLoading(true);
        AutomisedApis.getPublicPlanDetails(planId)
            .then(response => {
                const planDetails = response;
                setPublicPlanDetails(planDetails);
                setErrorLoadingData(false);
                document.title = `Automised Energy | Plan details: ${planId.slice(0, -4)} - ${planDetails.displayName}, by ${planDetails.brandName}`
            }).catch(error => {
                setPublicPlanDetails(null);
                console.error('Error fetching public plan details:', error);
                setErrorLoadingData(true);
                document.title = `Automised Energy | Plan details: ${planId.slice(0, -4)} - Error`
            })
            .finally(() => {
                setPublicPlanDetailsAreLoading(false);
            });

    }, [planId])

    return (
        <AccordionContext.Provider value={{ selectedItem, setSelectedItem }}>
            <div id="site">
                <HintProvider>
                    <PageBody className="public-plan-details" clickHandler={() => false}>

                        <a href="https://automised.energy" className="public-page-promotion-link">
                            <img className="logo" src='/assets/images/product-logo-mobile.svg' />
                            <h5>Regain control of your power bills with Automised Energy</h5>
                        </a>

                        {publicPlanDetailsAreLoading ? (
                            "Loading..."
                        ) : errorLoadingData ? (
                            `Could not load details for plan ID: ${planId}`
                        ) : publicPlanDetails ? (
                            <>
                                <HeroSummary publicPlanDetails={publicPlanDetails} />
                                <a href="https://automised.energy" className="public-page-promotion-link">
                                    <h5>How much could this plan save you?  Automised Energy will work that out for you in seconds</h5>
                                </a>
                                <PlanDetailsBreakdown planDetails={publicPlanDetails} />
                                <div className='meta-info'>
                                    <div><span className='planIdLabel'>Plan last updated by {publicPlanDetails.brandName}:</span> {(new Date(publicPlanDetails?.lastUpdated)).toDateString()}</div>
                                    {planId.slice(-3) === "EME" ? <div><span className='planIdLabel'><a href={`https://www.energymadeeasy.gov.au/plan?id=${planId.slice(0, -4)}&postcode=${publicPlanDetails.geography.includedPostcodes[0]}`} target="_blank">View plan at Energy Made Easy</a></span></div> : ''}
                                </div>
                            </>
                        ) : (
                            `No plan details available for plan ID: ${planId}.`
                        )}
                        <PageFooter />
                    </PageBody>
                    <HintModal />
                </HintProvider>
            </div>
        </AccordionContext.Provider>
    );

};

export default PublicPlanDetails;
