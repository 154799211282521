import Icon from "./Icon"

const Button = ({ type, id, icon, label, clickHandler, href, children, selected, className, enabled=true, disabledHref}) => {
    return (
        <a className={`button ${type} ${className ? className : ''} ${selected ? 'selected' : ''} ${enabled ? 'enabled' : 'disabled'}`} id={id} onClick={clickHandler ? ((event) => clickHandler(event)) : undefined} href={enabled ? href : disabledHref}>
            {icon ? <Icon name={icon} className={`icon ${icon}`} descriptiveLabel={`Icon for ${icon}`} /> : ''}
            {label || children}
        </a>
    )
}

export default Button