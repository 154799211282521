// Doorbell.js
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import './Doorbell.scss'

const Doorbell = () => {
  const { authenticatedUser, userProfile } = useContext(AuthContext); // Assuming AuthContext provides user data

  useEffect(() => {
    const doorbellOptions = {
      id: "14238",
      appKey: "bmyQsKjPjJnwrReE2j15xkrpdb9DeV8bdO04rQpHi3XfdQCInKkPKapoHkW8GEqG",
      hideEmail: true,
      properties: {
        username: userProfile?.consumerId ?? 'unknown'
      }
    };

    if (authenticatedUser && authenticatedUser.attributes?.email) {
      doorbellOptions.email = authenticatedUser.attributes?.email;
    }

    const loadScript = () => {
      if (window.doorbellOptions) {
        // Update existing doorbellOptions
        window.doorbellOptions.email = authenticatedUser ? authenticatedUser.attributes?.email : '';
        window.doorbellOptions.properties.username = userProfile?.consumerId ?? 'unknown';
        return;
      }

      window.doorbellOptions = doorbellOptions;

      const script = document.createElement('script');
      script.id = 'doorbellScript';
      script.type = 'text/javascript';
      script.crossOrigin = 'anonymous';
      script.async = true;
      script.src = `https://embed.doorbell.io/button/${doorbellOptions.id}?t=${new Date().getTime()}`;

      const headOrBody = document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0];
      headOrBody.appendChild(script);
    };

    if (document.readyState === 'complete') {
      loadScript();
    } else {
      window.addEventListener('load', loadScript, false);
    }

    return () => {
      window.removeEventListener('load', loadScript, false);
    };
  }, [authenticatedUser, userProfile]);

  return <></>; // This component does not render anything
};

export default Doorbell;
