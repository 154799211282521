import React, {useState} from 'react';
import SiteMenuItems from './SiteMenuItems';
import { useNavigate } from 'react-router-dom';

import './SiteMenuFooter.scss';
import SiteMenuItem from './SiteMenuItem';

const SiteMenuFooter = () => {
  const [selectedItem, setSelectedItem] = useState(window.location.pathname.split('/')[1] || 'dashboard');
  const navigate = useNavigate();

  const setSelectedItemHandler = (itemName) => {
    setSelectedItem(itemName);
    return true;
}

const logout = () => { navigate('/logout') };

  return (
    <div id="siteMenuFooter">
        <SiteMenuItems>
          <hr />
            <SiteMenuItem image="subscription" href="/subscription" selected={selectedItem === 'subscription'} handleClick={(event) => { setSelectedItemHandler('subscription'); }}>Subscription</SiteMenuItem>
            <SiteMenuItem image="profile" href="/profile" selected={selectedItem === 'profile'} handleClick={(event) => { setSelectedItemHandler('profile'); }}>Profile</SiteMenuItem>
            <SiteMenuItem image="help" href="/help" selected={selectedItem === 'help'} handleClick={(event) => { setSelectedItemHandler('help'); }}>Help</SiteMenuItem>
            <SiteMenuItem href="/logout" image="logout" handleClick={(event) => { logout(); }}>Logout</SiteMenuItem>
        </SiteMenuItems>
    </div>
  );
};

export default SiteMenuFooter;