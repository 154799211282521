import React, { useContext, useEffect, useState } from 'react';
import './HeroSummaryDash.scss';
import HeroGauge from './HeroGauge';
import Button from '../Common/Button';
import { ToggleGroup, ToggleOption } from '../Common/ToggleGroup';
import { DataContext } from '../../utils/DashboardDataContext';
import * as amplitude from "@amplitude/analytics-browser";
import { HintButton } from '../Common/Hints'
import { useHint } from '../../utils/HintContext';
import { PseudoUserProfileContext } from '../../utils/PseudoUserProfileContext';
import '@rmwc/dialog/styles';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import { AuthContext } from '../../utils/AuthContext';
import { SessionContext } from '../../utils/SessionContext';
import { CommonDataContext } from '../../utils/CommonDataContext';
import { updateUserProfile } from '../../utils/automisedAPIs';
import { StarRating } from '../Common/StarRating';

const HeroSummary = () => {
    const { planData, currentPlan, servicePoint, dataIsLoading, usageProfile, dashView, setDashView, energySavingSuggestions, displayAddress } = useContext(DataContext);
    const { userProfile } = useContext(CommonDataContext)
    const { demoUserId } = useContext(PseudoUserProfileContext);
    const { authenticatedUser } = useContext(AuthContext);
    const { sessionContext, setSessionContext } = useContext(SessionContext);
    const userLocale = navigator.language;
    const firstTime = !(userProfile?.congratsAcknowledged ?? false);

    //Costs
    const currentPlanCosts = planData.find(plan => plan.planId == 'current_plan');
    const annualCost = currentPlanCosts ? (currentPlanCosts.consumerCosts.total ? currentPlanCosts.consumerCosts.total / 100 : '?') : 0; // Divide by 100 = dollars
    const indexOfCurrentPlan = planData.findIndex(plan => plan.planId == 'current_plan');
    //const indexOfPreviousCosts = planData.findIndex(plan => plan.planId == 'actual_costs');
    const countOfBetterPlans = indexOfCurrentPlan;
    const minPlanCost = findItemWithSmallestTotalCost(planData)?.consumerCosts?.total / 100; //Ordered least -> most expensive, so first plan will be cheapest
    const maxPlanCost = findItemWithLargestTotalCost(planData)?.consumerCosts?.total / 100; //Ordered least -> most expensive, so last plan will be most expensive
    const planCostRange = maxPlanCost - minPlanCost;
    const maxPlanSavings = annualCost - minPlanCost;
    const annualCostPercentage = (maxPlanSavings / planCostRange) * 100;

    //Usage
    const annualAvgNetUsage = usageProfile?.averages.perYear.generalImport + (usageProfile?.averages.perYear.clImport || 0) - (usageProfile?.averages.perYear.export || 0);
    const dailyAvgNetUsage = annualAvgNetUsage / 365;
    const minAnnualAvgNetUsage = Math.min(annualAvgNetUsage, -50 * 365); //If the user's average usage is beyind the limits set here, then make the user's averge the limit
    const maxAnnualAvgNetUsage = Math.max(annualAvgNetUsage, 40 * 365); //If the user's average usage is beyind the limits set here, then make the user's averge the limit
    const annualAvgNetUsagePercentage = ((annualAvgNetUsage - minAnnualAvgNetUsage) / (maxAnnualAvgNetUsage - minAnnualAvgNetUsage)) * 100;
    const countOfEnergySavingSuggestions = energySavingSuggestions?.count || 0;

    //Subscription
    const isSubscribed = userProfile?.stripeProfile?.status === 'active';

    const demoDialogue = () => {

        const acknowledgeDemo = () => {
            setSessionContext({ ...sessionContext, demoAcknowledged: true });
            return true;
        };

        if (sessionContext.demoAcknowledged == false) {
            return (
                <div>
                    <Dialog open="true" onClose={(event) => { acknowledgeDemo(); }}>
                        <DialogTitle>Welcome!</DialogTitle>
                        <DialogContent>There is no data for <b>{authenticatedUser.attributes.email}</b>, so we'll show you some demo data instead.</DialogContent>
                        <DialogActions>
                            <DialogButton action="OK" isDefaulAction="true">OK</DialogButton>
                        </DialogActions>
                    </Dialog >
                </div >
            )
        }
    }

    const costView = () => {
        //currentPlan.planDetail.brandRating = undefined;
        //currentPlan.planDetail.ecoRating = undefined;

        return (
            <>
                <div id="heroHeader">
                    <h1>Your plan<HintButton topic="yourCosts" /></h1>
                    <div id="siteLocation"><span className='siteAddress' title={displayAddress}>{displayAddress}</span></div>
                </div>

                <div id="heroInfo">
                    <HeroGauge percent={annualCostPercentage} valuePrefix="$" value={new Intl.NumberFormat(userLocale, { maximumFractionDigits: 0 }).format(parseInt(annualCost))} valueCaption="Your cost p.a." />
                    <div id="heroDetails">
                        <span id="siteRetailer"><img className='brand-logo' src={`/assets/images/retailerLogos/${currentPlan.planOverview?.retailerBrand}.svg`} /> {currentPlan.planOverview?.retailerBrandName}</span>
                        <div className='detailItem' id='customerRatingDetail'>
                            <span className="detailLabel" id='customerRatingLabel'>Customer rating</span>
                            <span className='detailValue' id='customerRatingValue'>
                                {currentPlan.planDetail?.brandRating ?
                                    <>
                                        <StarRating id='brandRating' rating={currentPlan.planDetail?.brandRating} />
                                        <span className='ratingValue'>{currentPlan.planDetail?.brandRating}</span>
                                    </>
                                    :
                                    <span className='ratingValue unknown'>unknown</span>}
                                <HintButton topic="customerRating" />
                            </span>
                        </div>
                        <div className='detailItem' id='ecoRatingDetail'>
                            <span className="detailLabel" id='ecoRatingLabel'>Eco rating</span>
                            <span className='detailValue' id='ecoRatingValue'>
                                <StarRating id='ecoRating' rating={currentPlan.planDetail?.ecoRating} iconRated="eco" iconUnrated="eco" />
                                <span className='ratingValue'>{currentPlan.planDetail?.ecoRating}</span>
                                <HintButton topic="ecoRating" /></span>
                        </div>
                        <hr />
                        <div className='detailItem' id='betterPlansDetail'>
                            <span className="detailLabel" id='betterPlansLabel'>Better plans available</span>
                            <span className='detailValue' id='betterPlansValue'>{countOfBetterPlans}<HintButton topic="betterPlans" /></span>
                        </div>
                        <Button type="primary" id="betterPlans" label="See better plans" icon="filter" href="#betterPlansHeading" clickHandler={() => console.log('Clicked')} />
                    </div>
                </div>
            </>);

    }

    const usageView = () => {
        return (
            <>
                <div id="heroHeader">
                    <h1>Your energy<HintButton topic="yourUsage" /></h1>
                    <div id="siteLocation"><span className='siteAddress' title={displayAddress}>{displayAddress}</span></div>
                </div>

                <div id="heroInfo">
                    <HeroGauge percent={annualAvgNetUsagePercentage} valuePrefix="&nbsp;" value={new Intl.NumberFormat(userLocale, { maximumFractionDigits: 1 }).format(parseFloat(dailyAvgNetUsage))} valueCaption="Your daily kWh" />
                    <div id="heroDetails">
                        <span id="siteRetailer"><img className='brand-logo' src={`/assets/images/retailerLogos/${currentPlan.planOverview?.retailerBrand}.svg`} /> {currentPlan.planOverview?.retailerBrandName}</span>
                        <div className='detailItem customerRatingDetail'>
                            <span className='detailLabel customerRatingLabel'>Customer rating</span>
                            <span className='detailValue customerRatingValue'>
                                <StarRating id='brandRating' rating={currentPlan.planDetail?.brandRating} />
                                <span className='ratingValue'>{currentPlan.planDetail?.brandRating}</span>
                                <HintButton topic="customerRating" />
                            </span>
                        </div>
                        <div className='detailItem ecoRatingDetail'>
                            <span className='detailLabel ecoRatingLabel'>Eco rating</span>
                            <span className='detailValue ecoRatingValue'>
                                <StarRating id='ecoRating' rating={currentPlan.planDetail?.ecoRating} iconRated="eco" iconUnrated="eco" />
                                <span className='ratingValue'>{currentPlan.planDetail?.ecoRating}</span>
                                <HintButton topic="ecoRating" />
                            </span>
                        </div>
                        <hr />
                        <div className='detailItem' id='waysToReduceDetail'>
                            <span className="detailLabel" id='waysToReduceLabel'>Ways to save energy</span>
                            <span className='detailValue' id='waysToReduceValue'>+{countOfEnergySavingSuggestions} <HintButton topic="waysToReduce" /></span>
                        </div>
                        <Button type="primary" id="suggestionsButton" label="Suggestions" icon="filter" href="#waysToReduceHeading" clickHandler={() => console.log('Clicked')} />
                    </div>
                </div>
            </>);

    }

    useEffect(() => {
        amplitude.track(`Dashboard view was changed to ${dashView}`);
    }, [dashView])

    useEffect(() => {
        console.log(`demoUserId: ${demoUserId}`)
        console.log(`sessionContext: ${JSON.stringify(sessionContext)}`)
        console.debug(`Current plan: ${JSON.stringify(currentPlan)}`)
        console.debug(`Firsttime: ${firstTime}`)
    }, []);




    return (
        <>
            <div id="heroSummary" className={`content-tile primary ${dataIsLoading ? 'skeleton' : ''}`}>
                {demoUserId ? demoDialogue() : undefined}
                {/*<FirstTimeDialogue
                    firstTime={firstTime}
                    minPlanSavings={minPlanSavings}
                    onAcknowledge={() => {
                        setSessionContext({ ...sessionContext, congratsAcknowledged: true });
                        updateUserProfile(userProfile.consumerId, { congratsAcknowledged: true });
                        console.debug(`Acknowledging congrats modal`)
                        userProfile.congratsAcknowledged = true;
                    }}
                />*/}
                <UnlockSavingsDialogue maxPlanSavings={maxPlanSavings} isSubscribed={isSubscribed} countOfBetterPlans={countOfBetterPlans} sessionContext={sessionContext} setSessionContext={setSessionContext} />
                {dashView === 'cost' ? costView() : usageView()}
            </div>
            {/*<ToggleGroup level="primary" className={dataIsLoading ? 'skeleton' : ''} clickHandler={(selectedValue) => setDashView(selectedValue)}>
                <ToggleOption text="Cost" value="cost" />
                <ToggleOption text="Usage" value="usage" />
                </ToggleGroup>*/}
        </>
    );
};

const UnlockSavingsDialogue = ({ maxPlanSavings, countOfBetterPlans, sessionContext, setSessionContext, isSubscribed }) => {

    const handleClose = () => {
            //console.log(`Executing handleClose now to acknowledge modal.`)
            setSessionContext(oldContext => ({ ...oldContext, unlockSavingsAcknowledged: true }));
    };

    if (isSubscribed || maxPlanSavings < 250 || isNaN(maxPlanSavings) || sessionContext?.unlockSavingsAcknowledged === true) {
        //console.log(sessionContext)
        return null; // Don't render the dialog
    } else {
    }

    return (
        <div>
            <Dialog open="true" onClose={(event) => { handleClose(event); }} className='congratsModal hintDialog'>
                <DialogContent className='modalContent hintContent'>
                    <img src='/assets/images/moneyPots.png' />
                    <h1>Big savings found!</h1>
                    <p>We've found plans saving you up to <strong>${maxPlanSavings.toFixed(0)} every year</strong>! 🥳</p>
                    <p>You can browse the details of plans that will save you up to $250 per year for free.</p>
                    <p>To access the {countOfBetterPlans} plans that can save you more than $250 per year, you will be asked to subscribe to Automised Energy. </p>
                    <ul><li className='ctaItem' onClick={(event) => { handleClose(); }} >OK</li></ul>
                </DialogContent>
            </Dialog >
        </div >
    );
};

const FirstTimeDialogue = ({ firstTime, minPlanSavings, onAcknowledge }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false); // This closes the dialog
    };

    if (!firstTime || minPlanSavings <= 100 || isNaN(minPlanSavings)) {
        return null; // Don't render the dialog
    }

    return (
        <div>
            <Dialog open={open} onClose={(event) => { onAcknowledge(); }} className='congratsModal hintDialog'>
                <DialogContent className='modalContent hintContent'>
                    <img src='/assets/images/exchangeCurrency.png' />
                    <h1>CONGRATS</h1>
                    <p>We've identified better plans saving you up to <strong>${minPlanSavings.toFixed(0)} annually</strong>, offering both cost-effective and eco-friendly options.</p>
                    <ul><li className='ctaItem' onClick={handleClose} >Start saving now</li></ul>
                </DialogContent>
                <DialogActions className='hintActions'>
                    <DialogButton action="OK" isDefaulAction="true" className='hintButton'>Close</DialogButton>
                </DialogActions>
            </Dialog >
        </div >
    );
};


function findItemWithSmallestTotalCost(array) {
    const planWithSmallestTotalCost = array.reduce((minItem, currentItem) => {
        if (currentItem.consumerCosts && currentItem.consumerCosts.total < minItem.consumerCosts.total) {
            return currentItem;
        }
        return minItem;
    }, array[0]);

    //console.debug(`Plan with smallest total cost found to be ${planWithSmallestTotalCost?.planId} with cost of ${planWithSmallestTotalCost?.consumerCosts?.total}`)

    return planWithSmallestTotalCost;
}

function findItemWithLargestTotalCost(array) {
    return array.reduce((maxItem, currentItem) => {
        if (currentItem.consumerCosts && currentItem.consumerCosts.total > maxItem.consumerCosts.total) {
            return currentItem;
        }
        return maxItem;
    }, array[0]);
}


export default HeroSummary;