import React, { useContext, useEffect, useState } from 'react';
import './Eligibility.scss';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';

const Eligibility = ({ planDetails, eligibilityIsConfirmed, setEligibilityIsConfirmed }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    let content;

    const confirmEligibility = () => {
        console.debug(`Confirming eligibility`)
        setEligibilityIsConfirmed(!eligibilityIsConfirmed);
    }

    if (planDetails.electricityContract.eligibility) {
        content = planDetails.electricityContract.eligibility.map((eligibility, index) => {
            return (
                <div key={index}>
                    <h3>{eligibility.information}</h3>
                    <p>{eligibility.description}</p>
                </div>
            )
        })
        if (content.length > 0) {
            content.push(
                <div className='eligibility-confirmation' key="-1">
                    <h3>Confirm Eligibility</h3>
                    <label>I fulfil these eligibility requirements <input type="radio" onClick={(event) => confirmEligibility()} checked={eligibilityIsConfirmed} onChange={(e) => true} /></label>
                </div>
            )
        }
    } else {
        content = <p>There is no eligibility criteria for this plan.</p>
    }

    const switchNowClickHandler = (event) => {
        console.debug(`Switch now button clicked`)
        setSelectedItem('switch-via-email');
    }

    return (
        <div className="breakdownEligibility">
            {content}
            <div className='inlineCtaGroup'>
            {eligibilityIsConfirmed ?
                                <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-via-email" clickHandler={(e) => switchNowClickHandler(e)} />
                                :
                                null
                            }
             </div>
        </div>
    );
};


export default Eligibility;