import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { InsufficientUserAttributesException } from '../utils/Exceptions';

function AuthCallback() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function handleAuthResponse() {
      try {
        // This will exchange the code for tokens, if a code is present in the URL.
        const user = await getCurrentUser();

        console.log("From auth callback: Authenticated user:", user);

        amplitude.setUserId(user.username);
        console.debug(`Set the Amplitude userId as ${user.username}`)

        navigate('/'); // Redirect to a protected route or home
      } catch (error) {
        console.error('Error during authentication:', error);
        // Handle or redirect to an error page
        setError(new InsufficientUserAttributesException(`Could not access required user attributes: ${error.message}`));
        //navigate('/error');
      }
    }

    handleAuthResponse();
  }, [navigate]);

  if (error) {
    throw new Error(error.message);
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  return <div>Processing authentication...</div>;
}

export default AuthCallback;
