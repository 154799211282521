import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import { Amplify } from 'aws-amplify';
import { awsconfig } from '../utils/AmplifyConfig';
import { signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';
import './Login.scss'

Amplify.configure(awsconfig);

const Login = () => {
    const { authenticatedUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [content, setContent] = useState(LoggingInPlaceHolder);

    useEffect(() => {
        console.debug(`From Login: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)

        const isAuthenticated = getCurrentUser()
            .then((argument) => {
                console.debug(`From Login: isAuthenticated: ${JSON.stringify(argument)}`)
                //Is already logged in. Navigate to default route
                navigate('/');
                setContent(<p>Already logged in. Redirecting...</p>)
                return 'Already logged in. Redirecting...';
            })
            .catch((argument) => {
                console.debug(`From Login: Could not find authenticated user: ${JSON.stringify(argument)}. Signing in...`);
                setContent(<LoginScreen />)
                return false;
            });

    }, []);

    return content;
};

const LoggingInPlaceHolder = () => {
    return <div>Logging in...</div>
}

const handleSocialSignIn = async (provider) => {
    await signInWithRedirect({ provider });
    return false;
}

const LoginScreen = () => {
    return <div className="loginScreen">
        <div className="pageBanner">
            <img className="logo" alt="Automised logo" src="/assets/images/product-logo-mobile.svg" />
            <div className="burgerMenu">Burger menu</div>
        </div>
        <div className="loginSelector">
            <h1>Automised Energy sign in</h1>
            <div className="providers">
                <ProviderButton providerName="Google" />
                <ProviderButton providerName="Facebook" />
                <ProviderButton providerName="Apple" />
                <ProviderButton providerName="Amazon" />
            </div>
            <p className="loginDisclaimer">By signing up, you acknowledge that you have read, understood, and agree to Automised <a href="https://www.automised.energy/"> Terms </a> and <a href="https://www.automised.energy/privacy"> Privacy Policy</a>.</p>
        </div>
    </div>
}

const ProviderButton = ({ providerName }) => {
    return <button className="providerButton" onClick={() => { handleSocialSignIn(providerName) }}>
        <img className="providerIcon" src={`/assets/images/auth-providers/${providerName}.svg`} alt={`${providerName} icon`} />
        <span className="providerText">{`Sign in with ${providerName}`}</span>
    </button>
}

export default Login;
