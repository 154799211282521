import React, { useContext, useEffect, useState } from 'react';
import '../PlanDetails/PlanDetailsBreakdown.scss';
import Tariffs from '../PlanDetails/Tariffs';
import GreenPower from './GreenPower';
import Incentives from '../PlanDetails/Incentives';
import Eligibility from './Eligibility';
import { AccordionGroup, AccordionItem } from '../Common/AccordionGroup';
import Fees from './Fees';
import Terms from './Terms';


const PlanDetailsBreakdown = ({ planDetails }) => {
    const [hasEligibilityCriteria, setHasEligibilityCriteria] = useState(planDetails?.electricityContract?.eligibility && planDetails?.electricityContract?.eligibility?.length > 0);

    console.debug(`Plan details breakdown`, planDetails)

    return (
        <div className="detailsBreakdown content-tile primary">
            <h2 id="planDetailsHeading">Plan details</h2>
            <AccordionGroup>
                <AccordionItem id="rates" label="Rates" icon="costs">
                    <p className='note'>All prices include GST where applicable.</p>
                    <Tariffs planDetails={planDetails} noCTAs={true} />
                </AccordionItem>
                <AccordionItem id="greenPower" label="GreenPower" icon="greenpower">
                    <GreenPower planDetails={planDetails} />
                </AccordionItem>
                <AccordionItem id="incentives" label="Incentives" icon="bonus">
                    <Incentives planDetails={planDetails} noCTAs={true} />
                </AccordionItem>
                <AccordionItem id="eligibility" label="Eligibility" icon="eligibale2">
                    <Eligibility planDetails={planDetails} />
                </AccordionItem>
                <AccordionItem id="fees" label="Fees" icon="wallet">
                    <Fees planDetails={planDetails} />
                </AccordionItem>
                <AccordionItem id="terms" label="Terms" icon="attach_file">
                    <Terms planDetails={planDetails} />
                </AccordionItem>
            </AccordionGroup>
        </div>
    );
};


export default PlanDetailsBreakdown;