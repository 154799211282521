import React, { useContext, useEffect, useState } from 'react';
import '../PlanDetails/GreenPower.scss';
import { AccordionContext } from '../../utils/AccordionContext';
import './Fees.scss'

const Fees = ({ planDetails }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    if (!planDetails) return;

    const feesAr = planDetails.electricityContract.fees;
    const additionalFeeInformation = planDetails.electricityContract.additionalFeeInformation;

    if (!feesAr || feesAr.length == 0) {
        return <p>There are no fee details available for this plan</p>
    }

    return (
        <div className="fees">
            {feesAr.map((fee, index) => {
                return <div className='feeLine' key={index}>
                        <div className='feeLabel'>
                            <span className='feeDescription'>{fee.description}</span>
                        </div>
                        <span className='feePrice'>${fee.amount}</span>
                    </div>
            })
            }
            {additionalFeeInformation && <>
                <h3>NOTE:</h3>
                <p>{additionalFeeInformation}</p>
            </>}
        </div>
    );
};


export default Fees;