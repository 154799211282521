import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import './Subscription.scss';
import { CommonDataProvider } from '../utils/CommonDataContext';
import { SubscriptionContent } from '../components/Subscription/SubscriptionContent';

const Subscription = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Subscription page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <PageHeaderBar />
                    <SubscriptionContent />
                    <PageFooter />
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default Subscription;
