import React, { useContext, useEffect, useState } from 'react';
import './Tariffs.scss';
import Icon from '../Common/Icon';
import { HintButton } from '../Common/Hints';
import { lookup } from '../../utils/References';
import Button from '../Common/Button';
import TariffScheduleChart from './TariffScheduleChart';
import { parse as iso8601Parse } from 'iso8601-duration';
import { formatCents, formatCentsAddGst, formatCentsAsDollars, formatCentsAsDollarsAddGst, parseTariffWindowEdgeTime } from '../../utils/utils';
//import { AccordionContext } from '../../utils/AccordionContext';


const Tariffs = ({ planDetails, eligibilityIsConfirmed, noCTAs, textOnly }) => {
    //const { selectedItem, setSelectedItem } = useContext(AccordionContext);

    if (!planDetails) {
        return 'Loading plan details'
    }

    console.log(planDetails)

    const tariffPeriods = planDetails.electricityContract.tariffPeriod;
    const controlledLoads = planDetails.electricityContract.controlledLoad;
    const solarFeedInTariff = planDetails.electricityContract.enriched_solarFeedInTariff;
    var content = '';

    switch (planDetails?.electricityContract?.pricingModel) {
        case "SINGLE_RATE": {
            content = singleRate(tariffPeriods, solarFeedInTariff, textOnly);
        } break;
        case "SINGLE_RATE_CONT_LOAD": {
            content = singleRateCl(tariffPeriods, solarFeedInTariff, controlledLoads, textOnly);
        } break;
        case "TIME_OF_USE": {
            content = touRate(tariffPeriods, solarFeedInTariff, textOnly);
        } break;
        case "TIME_OF_USE_CONT_LOAD": {
            content = touRateCl(tariffPeriods, solarFeedInTariff, controlledLoads, textOnly);
        } break;
        case "FLEXIBLE": {
            content = flexible(tariffPeriods, solarFeedInTariff, textOnly);
        } break;
        case "FLEXIBLE_CONT_LOAD": {
            content = flexibleCl(tariffPeriods, solarFeedInTariff, controlledLoads, textOnly);
        } break;
        case "QUOTA": {
            content = quota(tariffPeriods, solarFeedInTariff, controlledLoads, textOnly);
        } break;

        default: {
            content = 'Could not find details'
        } break;
    }

    return textOnly ?
        (
            <>{content}</>
        )
        :
        (
            <div className="breakdownTariffs">
                {content}
                {/*
            {planDetails.planId != 'current_plan' && !noCTAs &&
                <div className='inlineCtaGroup'>
                    {eligibilityIsConfirmed ?
                        <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-now" clickHandler={() => setSelectedItem('switch-now')} />
                        :
                        <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                    }
                </div>
            }
        */}

            </div >
        );
};

const singleRate = (tariffPeriods, solarFeedInTariff, textOnly) => {
    if (!tariffPeriods) return 'No tariff period';

    //Anytime rates
    const anytimeRates = AnytimeTariffRates(tariffPeriods, textOnly);


    //Solar
    const solarRates = SolarTariffRate(solarFeedInTariff, textOnly);

    if (textOnly) {
        return (
            <>
                Anytime rate\n\n

                Usage rates\n
                {anytimeRates}\n

                Solar feed-in rates\n
                {solarRates}

            </>
        )
    } else {
        return (
            <>
                <h3 className='rate-heading'>Anytime rate<HintButton topic="singleRate" /></h3>
                <h4 className='rate-heading'>Usage rates</h4>
                {anytimeRates}
                <h4 className='rate-heading'>Solar feed-in rates</h4>
                <RateGroup>
                    {solarRates}
                </RateGroup>
            </>
        )
    }
}

const singleRateCl = (tariffPeriods, solarFeedInTariff, controlledLoads, textOnly) => {
    if (!tariffPeriods) return 'No tariff period';

    //Anytime rates
    const anytimeRates = AnytimeTariffRates(tariffPeriods, textOnly);

    //Controlled load rates
    const controlledLoadRates = ControlledLoadTariffRates(controlledLoads, textOnly);

    //Solar
    const solarRates = SolarTariffRate(solarFeedInTariff, textOnly);

    if (textOnly) {
        return (
            <>
                Anytime rate with Controlled Load\n\n

                General usage rates\n
                {anytimeRates}\n

                Controlled load usage rates\n
                {controlledLoadRates}\n

                Solar feed-in rates\n
                {solarRates}\n

            </>
        )
    } else {
        return (
            <>
                <h3 className='rate-heading'>Anytime rate with Controlled Load<HintButton topic="singleRate" /></h3>
                <h4 className='rate-heading'>General usage rates</h4>
                {anytimeRates}
                <h4 className='rate-heading'>Controlled load usage rates</h4>
                <RateGroup>
                    {controlledLoadRates}
                </RateGroup>
                <h4 className='rate-heading'>Solar feed-in rates</h4>
                <RateGroup>
                    {solarRates}
                </RateGroup>
            </>
        )
    }
}

const touRate = (tariffPeriods, solarFeedInTariff, textOnly) => {
    //TOU rates
    const touRates = TouTariffRates(tariffPeriods, textOnly);

    //Solar feed-in rates
    const solarRates = SolarTariffRate(solarFeedInTariff, textOnly);

    if (textOnly) {
        return (
            <>
                Time of use rates \n\n

                Usage rates\n
                {touRates}\n

                Solar feed-in rates \n
                {solarRates}\n
            </>
        )
    } else {
        return (
            <>
                <h3 className='rate-heading'>Time of use rates<HintButton topic="touRate" /></h3>

                <h4 className='rate-heading'>Usage rates</h4>
                {touRates}

                <h4 className='rate-heading'>Solar feed-in rates</h4>
                <RateGroup>
                    {solarRates}
                </RateGroup>
            </>
        )
    }
}

const touRateCl = (tariffPeriods, solarFeedInTariff, controlledLoads, textOnly) => {

    //TOU rates
    const touRates = TouTariffRates(tariffPeriods, textOnly);

    //Controlled Load rates
    const controlledLoadsRates = ControlledLoadTariffRates(controlledLoads, textOnly);

    //Solar feed-in rates
    const solarRates = SolarTariffRate(solarFeedInTariff, textOnly);

    if (textOnly) {
        return <>Time of use rates with Controlled Load: \n\n

            General rates\n
            {touRates}\n

            Controlled Load rates\n
            {controlledLoadsRates}\n

            Solar feed-in rates\n
            {solarRates}\n
        </>
    } else {
        return (
            <>
                <h3 className='rate-heading'>Time of use rates with Controlled Load<HintButton topic="touClRate" /></h3>

                <h4 className='rate-heading'>Usage rates</h4>
                {touRates}

                <h4 className='rate-heading'>Controlled load rates</h4>
                <RateGroup>
                    {controlledLoadsRates}
                </RateGroup>

                <h4 className='rate-heading'>Solar feed-in rates</h4>
                <RateGroup>
                    {solarRates}
                </RateGroup>

            </>
        )
    }
}

const flexible = (tariffPeriods) => {
    return (
        <>
            Flexible rate details
        </>
    )
}

const flexibleCl = (tariffPeriods) => {
    return (
        <>
            Flexible rate with controlled load details
        </>
    )
}

const quota = (tariffPeriods) => {
    return (
        <>
            Quota rate details
        </>
    )
}




const RateGroup = ({ children, textOnly }) => {
    if (textOnly) {
        return { children };
    } else {
        return (
            <div className='rateGroup'>
                {children}
            </div>
        )
    }
}

const Rate = ({ rateName, mood, times, cents, units }) => {
    var iconName = '';
    //Determine icon for mood
    switch (mood) {
        case 'neutral': {
            iconName = 'Shoulder'
        } break;
        case 'good': {
            iconName = 'Off-peak'
        } break;
        case 'bad': {
            iconName = 'Peak'
        } break;
        case 'meh': {
            iconName = 'global'
        } break;
        default: {
            iconName = ''
        } break;
    }

    return (
        <div className='rateLine'>
            <Icon name={iconName} descriptiveLabel='Tariff rate mood' />
            <div className='rateLabels'>
                <span className='rateName'>{rateName}</span>
                <span className='rateTimes'> {times ? '(' + times + ')' : ''}</span>
            </div>
            <span className='ratePrice'>{cents}</span>
            <span className='ratePriceUnits'>{units}</span>
        </div>
    )
}











const TouTariffRates = (tariffPeriods, textOnly) => {
    const [tariffPeriodIndex, setTariffPeriodIndex] = useState(0);
    //Filter to only timeOfUseRates periods
    tariffPeriods = tariffPeriods.filter((rateForPeriod, index) => { return rateForPeriod.rateBlockUType == 'timeOfUseRates' })

    //Single out one rate block from the various TOU tariff periods
    const tariffPeriod = tariffPeriods[tariffPeriodIndex];

    if (!tariffPeriod?.timeOfUseRates) return '';

    //const timeScheduleDescriptions = summariseRateSchedule(tariffPeriod.timeOfUseRates);
    const timeScheduleDescriptions = generateDescriptions(tariffPeriod);

    const usageRates = tariffPeriod.timeOfUseRates.flatMap((timeOfUseRate, index) => {
        var mood = 'none', rateName = 'Rate', times = '', order, rateComponents;
        //times = generateDescription(timeOfUseRate);
        times = timeScheduleDescriptions[timeOfUseRate.type];

        //Type of this rate
        switch (timeOfUseRate.type) {
            case 'PEAK': {
                rateName = 'Peak rate';
                mood = 'bad';
                order = 1;
            } break;
            case 'SHOULDER': {
                rateName = 'Shoulder rate';
                mood = 'neutral';
                order = 2;
            } break;
            case 'OFF_PEAK': {
                rateName = 'Off-peak rate'
                mood = 'good';
                order = 3;
            } break;
            case 'SOLAR_SPONGE': {
                rateName = 'Solar sponge rate'
                mood = 'good';
                order = 4;
            } break;
        }

        //Block rates?
        rateComponents = timeOfUseRate.rates.map((rate, index) => {
            const measureUnit = rate.measureUnit || 'kWh';
            const description = `${rate.volume ? ((index == 0) ? 'First ' + rate.volume + measureUnit + ' ' : 'Next ' + rate.volume.measureUnit + ' ') : ((index > 0) ? ('Remaining usage ') : (''))}${times}`;
            if (textOnly) {
                return `${rateName} (${description.replace(/\.$/, '')}): ${formatCentsAddGst(rate.unitPrice)} ${`c/${measureUnit}`} \n`;
            } else {
                return <Rate order={order} mood={mood} rateName={rateName} times={description.replace(/\.$/, '')} cents={formatCentsAddGst(rate.unitPrice)} units={`c/${measureUnit}`} key={`${rateName}${index}`} />
            }
        });

        return rateComponents;
    })

    if (!textOnly) {
        //Sort by order
        usageRates.sort((a, b) => {
            return a.props.order - b.props.order;
        })
    }

    //Add supply charge
    usageRates.push(
        textOnly ?
            `General supply charge: ${tariffPeriod.dailySupplyCharges ? formatCentsAddGst(tariffPeriod.dailySupplyCharges) : 'unknown'} c/day \n`
            :
            <Rate mood="meh" rateName="General supply charge" times="per day" cents={tariffPeriod.dailySupplyCharges ? formatCentsAddGst(tariffPeriod.dailySupplyCharges) : 'unknown'} units="c/day" key={usageRates.length} />
    )


    const handleTariffPeriodSelection = (index) => {
        setTariffPeriodIndex(index);
    }

    var tariffPeriodSelector;

    if (tariffPeriods.length > 1) {
        tariffPeriodSelector = tariffPeriods.map((tariffPeriod, index) => {
            const season = getPredominantSeason(tariffPeriod.startDate, tariffPeriod.endDate);
            return <Button label={season} type="ternary" clickHandler={() => handleTariffPeriodSelection(index)} selected={index == tariffPeriodIndex} />
        });
    }

    //TOU visual card
    let touVisual = <div className="touVisualCard">
        <div className="headline">
            <Icon name="date-range" descriptiveLabel="calendar" />
            <span className='calendarRange'>{convertDate(tariffPeriod.startDate)} - {convertDate(tariffPeriod.endDate)}</span>
        </div>
        <div className="graph">
            <TariffScheduleChart timeOfUseRates={tariffPeriod.timeOfUseRates} />
        </div>
        <div className='periodSelector'>
            {tariffPeriodSelector}
        </div>
    </div>

    if (textOnly) {
        return (
            <>
                {usageRates}
            </>
        )
    } else {
        return (
            <>
                <RateGroup>
                    {usageRates}
                </RateGroup>
                {touVisual}
            </>
        )
    }
}


const AnytimeTariffRates = (tariffPeriods, textOnly) => {
    const [tariffPeriodIndex, setTariffPeriodIndex] = useState(0);
    const tariffPeriod = tariffPeriods[tariffPeriodIndex];


    //Block rates?
    //Consolidate dodgy rate blocks that have various blocks of the same price
    let consolidatedRates = [];
    tariffPeriod.singleRate.rates.forEach((rate, index) => {
        if (tariffPeriod.singleRate.rates.length > index + 1) { //There is at least one more rate in this array
            if (rate.unitPrice == tariffPeriod.singleRate.rates[index + 1].unitPrice) { //Does the next rate have the same price as this rate?
                if (rate.volume) {
                    rate.volume += (tariffPeriod.singleRate.rates[index + 1].volume || 0)
                }
                consolidatedRates.push(rate);
            }
        } else { //This is the last rate in the array. Add it.
            consolidatedRates.push(rate);
        }
    });
    console.debug(consolidatedRates)

    let rateComponents = consolidatedRates.map((rate, index) => {
        const measureUnit = 'kWh';
        const times = tariffPeriod.singleRate.period ? formatIntuitiveDuration(tariffPeriod.singleRate.period) : 'every day';
        let description;
        if (consolidatedRates.length == 1) {
            description = 'any time';
        } else {
            description = `${rate.volume ? ((index == 0) ? 'first ' + rate.volume + measureUnit + ' ' : 'next ' + rate.volume.measureUnit + ' ') : ((index > 0) ? ('additional usage ') : ('all day, '))}${times}`;
            description = description.replace(/\.$/, '').trim();
        }
        return textOnly ?
            `Usage rate${description ? ` (${description})` : ``}: ${formatCentsAddGst(rate.unitPrice)} c/kWh\n`
            :
            <Rate order={index} mood="meh" rateName="Usage rate" times={description} cents={formatCentsAddGst(rate.unitPrice)} units="c/kWh" key={index} />
    });

    const handleTariffPeriodSelection = (index) => {
        setTariffPeriodIndex(index);
    }

    var tariffPeriodSelector;

    if (tariffPeriods.length > 1) {
        tariffPeriodSelector = tariffPeriods.map((tariffPeriod, index) => {
            const season = getPredominantSeason(tariffPeriod.startDate, tariffPeriod.endDate);
            return <Button label={season} type="ternary" clickHandler={() => handleTariffPeriodSelection(index)} selected={index == tariffPeriodIndex} key={index} />
        });
    }


    if (textOnly) {
        return <>
            {rateComponents}
            Supply charge: {tariffPeriod.dailySupplyCharges ? formatCentsAddGst(tariffPeriod.dailySupplyCharges) : `unknown`} c/day \n
        </>
    } else {
        return (
            <>
                <RateGroup>
                    {rateComponents}
                    <Rate mood="meh" rateName="Supply charge" times="per day" cents={tariffPeriod.dailySupplyCharges ? formatCentsAddGst(tariffPeriod.dailySupplyCharges) : 'unknown'} units="c/day" key='-1' />
                </RateGroup>

                {tariffPeriodSelector && <div className='periodSelector'>
                    {tariffPeriodSelector}
                </div>}
            </>
        )
    }
}


//Solar feed-in rates
const SolarTariffRate = (solarFeedInTariff, textOnly) => {
    if (!solarFeedInTariff || solarFeedInTariff.length == 0) return textOnly ? "This plan has no feed-in tariff \n" : <Rate mood="bad" rateName="This plan has no feed-in tariff" key="0" />;

    const solarRates = solarFeedInTariff.map((solarTariff, index) => {
        const tariffRate = solarTariff.singleTariff; //TODO: Currently all solar tariff data is non-conformant to CDR specs and only uses the 'singleTariff' delimiter. This will change at some point and we must change with it when it does...
        let rateComponent;

        if (tariffRate) {
            //Block rates?
            const measureUnit = 'kWh';
            const description = `${tariffRate.enriched_volume ? ((index == 0) ? 'First ' + tariffRate.enriched_volume + measureUnit : 'Next ' + tariffRate.enriched_volume + measureUnit) : ((index > 0) ? 'Additional exports' : '')}`;
            rateComponent = textOnly ?
                `${solarTariff.displayName ?? 'Solar FiT'}${description ? ` (${description.replace(/\.$/, '')})` : ``}: ${formatCents(parseInt(tariffRate.amount))} c/${measureUnit} \n`
                :
                <Rate mood="good" rateName={solarTariff.displayName ?? 'Solar FiT'} times={description.replace(/\.$/, '')} cents={formatCents(parseInt(tariffRate.amount))} units={`c/${measureUnit}`} key={`${solarTariff.displayName}${index}`} />
        } else {
            rateComponent = 'Unknown'
        }

        return rateComponent;
    });

    return solarRates;
}

const ControlledLoadTariffRates = (controlledLoads, textOnly) => {
    const controlledLoadsRates = controlledLoads.map((cl) => {
        //The controlled load structure of plan details is currently non-conformant (e.g. missing rateBlockUType). CDR rectification schedule has this being rectified by 29 March 2024. We will need to change the logic here to match when they make those changes :-(.
        let ratesAr;

        if (cl.rateBlockUType) {
            ratesAr = cl[cl.rateBlockUType]?.rates;
        } else {
            ratesAr = cl.rates;
        }
        //Block rates?
        const rateComponents = ratesAr?.map((rate, index) => {
            const measureUnit = rate.measureUnit || 'kWh';
            const description = `${rate.volume ? ((index == 0) ? 'First ' + rate.volume + measureUnit + ' ' : 'Next ' + rate.volume + measureUnit + ' ') : ((index > 0) ? ('Remaining usage ') : (''))}${cl.enriched_clTimes || 'Unknown schedule'}`;
            return textOnly ?
                `${cl.displayName} (${description.replace(/\.$/, '')}): ${formatCentsAddGst(rate.unitPrice)} c/${measureUnit} \n`
                :
                <Rate mood="good" rateName={cl.displayName} times={description.replace(/\.$/, '')} cents={formatCentsAddGst(rate.unitPrice)} units={`c/${measureUnit}`} key={`${cl.displayName}${index}`} />
        });

        rateComponents?.push(textOnly ?
            `${cl.enriched_clCode} supply charge: ${formatCentsAddGst(cl.dailyCharge ?? 0)} c/day \n`
            :
            <Rate mood="meh" rateName={`${cl.enriched_clCode} supply charge`} times="per day" cents={formatCentsAddGst(cl.dailyCharge ?? 0)} units="c/day" key={ratesAr.length} />
        )

        return rateComponents;
    });

    return controlledLoadsRates
}







const formatIntuitiveDuration = (isoDuration) => {
    const duration = iso8601Parse(isoDuration);

    if (duration.years === 1) return 'per year';
    if (duration.months === 1) return 'every month';
    if (duration.weeks === 1) return 'every week';
    if (duration.days === 1) return 'each day';
    if (duration.hours === 1) return 'every hour';
    // Add more checks as needed for minutes and seconds

    // Default to a more detailed format if no single unit matches
    let formatted = '';
    if (duration.years) formatted += duration.years + ' years ';
    if (duration.months) formatted += duration.months + ' months ';
    if (duration.days) formatted += duration.days + ' days ';
    // Continue for hours, minutes, seconds
    return formatted.trim();
};


function roundToNearestHalfHour(timeStr) {
    const parsedTime = parseTariffWindowEdgeTime(timeStr);
    let hours = parsedTime.hour();
    let minutes = parsedTime.minute();

    // Round minutes to the nearest half hour
    minutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 60;
    if (minutes === 60) {
        hours += 1;
        minutes = 0;
    }

    const amPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours === 0 ? 12 : hours;

    return `${hours}${minutes === 0 ? '' : ':30'}${amPm}`;
}

function formatTouTimes(timeOfUsePeriod) {
    const startTime = roundToNearestHalfHour(timeOfUsePeriod.startTime);
    const endTime = roundToNearestHalfHour(timeOfUsePeriod.endTime);
    let timeRange = `${startTime} - ${endTime}`;

    if (startTime.substring(0, startTime.length - 2) == endTime.substring(0, startTime.length - 2)) timeRange = 'all day';

    return timeRange;
}

function consolidateTou(touArray) {
    let dayWiseObject = {}; //A map of all shortened days to start and end times for this TOU array

    const fullDayMappings = {
        'BUSINESS_DAYS': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        'WEEKENDS': ['Sat', 'Sun'],
        'MONDAY': 'Mon',
        'TUESDAY': 'Tue',
        'WEDNESDAY': 'Wed',
        'THURSDAY': 'Thu',
        'FRIDAY': 'Fri',
        'SATURDAY': 'Sat',
        'SUNDAY': 'Sun',
        'MON': 'Mon',
        'TUE': 'Tue',
        'WED': 'Wed',
        'THU': 'Thu',
        'FRI': 'Fri',
        'SAT': 'Sat',
        'SUN': 'Sun'
    };
    const dayOrder = {
        'Mon': 1,
        'Tue': 2,
        'Wed': 3,
        'Thu': 4,
        'Fri': 5,
        'Sat': 6,
        'Sun': 7
    };

    touArray.forEach(tou => {
        const days = tou.days;
        //Standardise and order the TOU days array
        const standardizedDays = days.flatMap(day => fullDayMappings[day] || day);
        standardizedDays.sort((a, b) => dayOrder[a] - dayOrder[b]);

        //Build up dayWise object mapping of times
        standardizedDays.forEach(day => {
            if (!dayWiseObject[day]) dayWiseObject[day] = [];
            dayWiseObject[day].push({ startTime: tou.startTime, endTime: tou.endTime })
        });

    });

    //consolidate the mappings
    Object.keys(dayWiseObject).forEach(day => {
        const timesArray = dayWiseObject[day];
        const consolidatedTimePeriods = consolidateTimePeriods(timesArray);
        dayWiseObject[day] = consolidatedTimePeriods;
    });

    return dayWiseObject;

}

function consolidateTimePeriods(timePeriods) {
    // Convert time string to minutes for easier comparison
    const timeToMinutes = (timeStr) => {
        const hours = parseInt(timeStr.substring(0, timeStr.length - 2), 10) || 0;
        const minutes = timeStr.length > 2 ? parseInt(timeStr.substring(timeStr.length - 2), 10) : (parseInt(timeStr, 10) || 0);
        return hours * 60 + minutes;
    };

    // Sort the periods by startTime
    const sortedPeriods = [...timePeriods].sort((a, b) => timeToMinutes(a.startTime) - timeToMinutes(b.startTime));

    let consolidated = [];
    let currentPeriod = sortedPeriods[0];

    for (let i = 1; i < sortedPeriods.length; i++) {
        const nextPeriod = sortedPeriods[i];
        const currentEndTime = timeToMinutes(currentPeriod.endTime);
        const nextStartTime = timeToMinutes(nextPeriod.startTime);

        // Check if the startTime of the next period is within 15 minutes of the current endTime
        if (nextStartTime - currentEndTime <= 15 || nextStartTime - currentEndTime + 1440 <= 15) {
            // Extend the current period's endTime to the next period's endTime
            currentPeriod.endTime = nextPeriod.endTime;
        } else {
            // No overlap, push the current period to the result and start a new one
            consolidated.push(currentPeriod);
            currentPeriod = nextPeriod;
        }
    }

    // Add the last period
    consolidated.push(currentPeriod);

    return consolidated;
}



function consolidateTimePeriodsByDays(schedule) {
    let consolidated = [];

    for (const [day, periods] of Object.entries(schedule)) {
        periods.forEach(period => {
            // Check if this period already exists in the consolidated array
            let existingPeriod = consolidated.find(p =>
                p.startTime === period.startTime && p.endTime === period.endTime
            );

            if (existingPeriod) {
                // If the period exists, add the current day to it
                existingPeriod.days.push(day);
            } else {
                // If not, create a new entry
                consolidated.push({
                    days: [day],
                    startTime: period.startTime,
                    endTime: period.endTime
                });
            }
        });
    }

    return consolidated;
}

function shortenDays(days) {
    let shortenedDescription;

    const fullDayMappings = {
        'BUSINESS_DAYS': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        'WEEKENDS': ['Sat', 'Sun'],
        'MONDAY': 'Mon',
        'TUESDAY': 'Tue',
        'WEDNESDAY': 'Wed',
        'THURSDAY': 'Thu',
        'FRIDAY': 'Fri',
        'SATURDAY': 'Sat',
        'SUNDAY': 'Sun',
        'MON': 'Mon',
        'TUE': 'Tue',
        'WED': 'Wed',
        'THU': 'Thu',
        'FRI': 'Fri',
        'SAT': 'Sat',
        'SUN': 'Sun'
    };
    const dayOrder = {
        'Mon': 1,
        'Tue': 2,
        'Wed': 3,
        'Thu': 4,
        'Fri': 5,
        'Sat': 6,
        'Sun': 7
    };

    //Standardise and order the array
    const standardizedDays = days.flatMap(day => fullDayMappings[day] || day);
    standardizedDays.sort((a, b) => dayOrder[a] - dayOrder[b]);

    //Find any consecutive runs within the array of days
    let consecutiveRuns = [], currentRun = [standardizedDays[0]]
    for (let i = 1; i < standardizedDays.length; i++) {
        const day = standardizedDays[i];
        const currentRunLatestIndex = dayOrder[currentRun[currentRun.length - 1]]
        const dayIndex = dayOrder[day];
        if (dayIndex == (currentRunLatestIndex + 1)) {
            currentRun.push(day);
        } else {
            consecutiveRuns.push([...currentRun]);
            currentRun = [day];
        }
    };

    // Check for the last run
    if (currentRun.length > 0) {
        consecutiveRuns.push(currentRun);
    }

    //Join any runs found with a hyphen e.g. "Mon - Wed" or "Fri - Sun"
    const runs = consecutiveRuns.map(run => {
        let shortenedDays = `${run[0]} - ${run[run.length - 1]}`;
        if (shortenedDays == 'Mon - Fri') {
            shortenedDays = 'weekdays';
        } else
            if (shortenedDays == 'Sat - Sun') {
                shortenedDays = 'weekends';
            } else
                if (shortenedDays == 'Mon - Sun') {
                    shortenedDays = 'every day'
                }
        return shortenedDays;
    })

    //Join multiple runs with a comma e.g. "Mon - Wed, Fri - Sun"
    shortenedDescription = runs.join(', ');

    return shortenedDescription;
}

function generateDescriptions(tariffPeriod) {
    const descriptions = [];

    tariffPeriod.timeOfUseRates.forEach(rate => {
        const consolidatedTou = consolidateTou(rate.timeOfUse);
        const normalisedConsolidatedTou = consolidateTimePeriodsByDays(consolidatedTou);

        let periodDescriptions = [];
        normalisedConsolidatedTou.forEach(touPeriod => {
            const daysShort = shortenDays(touPeriod.days);
            const mergedTimesDescription = formatTouTimes(touPeriod);
            periodDescriptions.push(`${mergedTimesDescription} ${daysShort}`);
        });
        descriptions[rate.type] = periodDescriptions.join(', ');
    });

    return descriptions;
}














const convertDate = (dateStr) => {
    // Assuming the year is not important for the conversion
    const year = new Date().getFullYear();
    const date = new Date(`${year}-${dateStr}`);

    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

function getPredominantSeason(startDate, endDate) {
    // Function to parse 'MM-DD' format and create a Date object (assuming a non-leap year)
    const parseDate = (dateStr) => new Date(`2021-${dateStr}`);

    // Define the start and end dates of each season
    const seasons = {
        Summer: [parseDate('12-01'), parseDate('02-28')],
        Autumn: [parseDate('03-01'), parseDate('05-31')],
        Winter: [parseDate('06-01'), parseDate('08-31')],
        Spring: [parseDate('09-01'), parseDate('11-30')]
    };

    // Parse the input dates
    let start = parseDate(startDate);
    let end = parseDate(endDate);

    // Handle year wrap
    if (end < start) {
        end.setFullYear(end.getFullYear() + 1);
    }

    // Function to calculate days in a date range
    const calculateDays = (start, end) => (end - start) / (1000 * 60 * 60 * 24);

    // Calculate the number of days the date range falls in each season
    let daysInSeason = {};
    for (const season in seasons) {
        let [seasonStart, seasonEnd] = seasons[season];

        // Adjust season end year for year wrap
        if (seasonEnd < seasonStart) {
            seasonEnd.setFullYear(seasonEnd.getFullYear() + 1);
        }

        // Calculate overlap
        let overlapStart = start < seasonStart ? seasonStart : start;
        let overlapEnd = end > seasonEnd ? seasonEnd : end;
        if (overlapStart < overlapEnd) {
            daysInSeason[season] = calculateDays(overlapStart, overlapEnd);
        } else {
            daysInSeason[season] = 0;
        }
    }

    // Find the season with the maximum days of overlap
    const season = Object.keys(daysInSeason).reduce((a, b) => daysInSeason[a] > daysInSeason[b] ? a : b);
    return season;
}


export default Tariffs;