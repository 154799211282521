import React, { useContext, useEffect, useState } from 'react';
import './PlanDetailsBreakdown.scss';
import CostBreakdown from './CostBreakdown';
import Tariffs from './Tariffs';
import GreenPower from './GreenPower';
import Incentives from './Incentives';
import Eligibility from './Eligibility';
import { AccordionGroup, AccordionItem } from '../Common/AccordionGroup';


const PlanDetailsBreakdown = ({ planDetails, currentPlanCosts, totalImports, costDivisorMap, costDivisor, setGranularity, eligibilityIsConfirmed, setEligibilityIsConfirmed }) => {
    const [hasEligibilityCriteria, setHasEligibilityCriteria] = useState(planDetails?.electricityContract?.eligibility && planDetails?.electricityContract?.eligibility?.length > 0);

    console.debug(`Plan details breakdown`, planDetails)

    return (
        <div className="detailsBreakdown content-tile primary">
            <h2 id="planDetailsHeading">Plan details</h2>
            <AccordionGroup>
                <AccordionItem id="costs" label="Cost Breakdown" icon="cost-breakdown">
                    <CostBreakdown planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                </AccordionItem>
                <AccordionItem id="rates" label="Rates" icon="tariff">
                    <Tariffs planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                </AccordionItem>
                <AccordionItem id="greenPower" label="GreenPower" icon="greenpower">
                    <GreenPower planDetails={planDetails} totalImports={totalImports} currentPlanCosts={currentPlanCosts} costDivisorMap={costDivisorMap} costDivisor={costDivisor} setGranularity={setGranularity} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                </AccordionItem>
                {planDetails.electricityContract.incentives && planDetails.electricityContract.incentives.length > 0 &&
                    <AccordionItem id="incentives" label="Incentives" icon="bonus">
                        <Incentives planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                    </AccordionItem>
                }
                {(planDetails.planId != 'current_plan') && hasEligibilityCriteria &&
                    <AccordionItem id="eligibility" label="Eligibility" icon="eligibale2">
                        <Eligibility planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} setEligibilityIsConfirmed={setEligibilityIsConfirmed}/>
                    </AccordionItem>
                }
            </AccordionGroup>
        </div>
    );
};


export default PlanDetailsBreakdown;