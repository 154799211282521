import React, { createContext, useContext } from 'react';
import { AwsRum } from 'aws-rum-web';

const AwsRumContext = createContext(null);

export const AwsRumProvider = ({ children }) => {
  let awsRum;

  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: process.env.REACT_APP_RUM_ID_POOL,
      endpoint: process.env.REACT_APP_RUM_ENDPOINT,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true
    };

    const APPLICATION_ID = process.env.REACT_APP_RUM_APP_ID;
    const APPLICATION_VERSION = process.env.REACT_APP_RUM_APP_VERSION;
    const APPLICATION_REGION = process.env.REACT_APP_RUM_APP_REGION;

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );

    awsRum.enable();

    console.log('AWS RUM initialized OK');

  } catch (error) {
    console.error('RUM failed to initialise', error);
  }

  return (
    <AwsRumContext.Provider value={awsRum}>
      {children}
    </AwsRumContext.Provider>
  );
};

export const useAwsRum = () => useContext(AwsRumContext);

export { AwsRumContext };
