import { useContext, useEffect, useState } from 'react';
import { useNavigate, redirect, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { Amplify } from 'aws-amplify';
import { awsconfig } from './AmplifyConfig';
import { getCurrentUser } from 'aws-amplify/auth';
import systemError from '../pages/ErrorPage';

Amplify.configure(awsconfig);

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { authenticatedUser, userProfile, userProfileCheckComplete } = useContext(AuthContext);
    const [hasProfile, setHasProfile] = useState(false);
    const [content, setContent] = useState('Authenticating');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.debug(`From ProtectedRoute: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)

        const isAuthenticated = getCurrentUser()
            .then((argument) => {
                console.debug(`From protectedRoute: isAuthenticated: ${JSON.stringify(argument)}`)
                return true;
            })
            .catch((error) => {
                console.debug(`From protectedRoute: Could not find authenticated user: ${JSON.stringify(error)}. Signing in...`)
                //signInWithRedirect({provider: "Google"});
                navigate("/login")
                return false;
            });
/*
        if (!authenticatedUser) {
            navigate("/login")
        }*/

    }, []);

    useEffect(() => {
        console.debug(`User profile changed`);
        if (!authenticatedUser || !userProfileCheckComplete) return;

        if (userProfile && Object.keys(userProfile).length == 0) { //Empty user profile objects represents a new user
            console.debug(`No user profile for authenticated user of ${authenticatedUser?.attributes?.name} (${authenticatedUser?.attributes?.email}).`)
            if (location.pathname !== '/newUser') {
                console.debug(`Redirecting to /newUser`)
                navigate('/newUser');
            }
            setHasProfile(false);
            setContent(<Component {...rest} />);
        } else if (!userProfile) { //An undefined userProfile object represents an inability to tell if the user already exists or not (e.g. system error, could not authenticate user)
            console.debug(`User profile: ${JSON.stringify(userProfile)}`)
            setHasProfile(false);
            //setContent(systemError());
            throw new Error(`Encountered an undefined userProfile object. This represents an inability to tell if the user already exists or not (e.g. system error, could not authenticate user)`);
        } else { //A populated userProfile means the user already exists
            setHasProfile(true);
            setContent(<Component {...rest} />);
        }
    }, [userProfileCheckComplete, authenticatedUser, userProfile, navigate])

    return content;

};


export default ProtectedRoute;
