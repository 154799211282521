import React, { useContext, useEffect, useState } from 'react';
import '../PlanDetails/GreenPower.scss';
import { AccordionContext } from '../../utils/AccordionContext';
import './Fees.scss'

const Terms = ({ planDetails }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    if (!planDetails) return;

    const terms = planDetails.electricityContract.terms;
    const isFixed = planDetails.electricityContract.isFixed;
    const termType = planDetails.electricityContract.termType;
    const variation = planDetails.electricityContract.variation;
    const onExpiryDescription = planDetails.electricityContract.onExpiryDescription;

    return (
        <div className="terms">
            {}
            {terms && <><h3>Conditions</h3><p>{terms}</p></>}
            {variation && <><h3>Rate changes</h3><p>{variation}</p></>}
            {onExpiryDescription && <><h3>Contract expiry</h3><p>{onExpiryDescription}</p></>}
        </div>
    );
};


export default Terms;