import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';
import { BillsHero } from '../components/Bills/BillsHero.js';
import { BillsHistory } from '../components/Bills/BillsHistory.js';
import { PseudoUserProfileContext } from '../utils/PseudoUserProfileContext.js';
import * as EnbraceAPIs from '../utils/automisedAPIs.js';
import { DataProvider } from '../utils/DashboardDataContext.js';
import { AccordionContext } from '../utils/AccordionContext.js';

const Bills = () => {
    const { authenticatedUser, userProfile } = useContext(AuthContext);
    const { demoUserId, demoUserProfiles } = useContext(PseudoUserProfileContext);
    const [billingData, setBillingData] = useState(null);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    console.debug(`In bills page`)

    useEffect(() => {
        amplitude.track(`Bills page rendered`);

        console.debug(`In bills page effect`)

        if (billingData != null) return; //We've already got billing data. No need to get it again.

        if (userProfile || demoUserId) {
            const consumerId = userProfile?.consumerId ? userProfile.consumerId : demoUserProfiles[demoUserId].selectedConsumer;
            const accountId = userProfile?.consumerId ? userProfile.defaultServicePoint.accountId : demoUserProfiles[demoUserId].selectedConsumerAccount;
            const servicePointId = userProfile?.consumerId ? userProfile.defaultServicePoint.servicePointId : demoUserProfiles[demoUserId].selectedServicePoint;

            EnbraceAPIs.getBillingData(consumerId, accountId, servicePointId)
                .then(billingDataResponse => {
                    setBillingData(billingDataResponse)
                    setError(null)
                })
                .catch(error => {
                    setError(error);
                    //throw new Error(`Error encountered while getting billing data`)
                })
        }
    }, [userProfile, demoUserId])


    if (authenticatedUser) {
        let content;

        return (
            <Site>
                <CommonDataProvider>
                    <DataProvider>
                        <AccordionContext.Provider value={{ selectedItem, setSelectedItem }}>
                            <PageHeaderBar />
                            <BillsHero billingData={billingData} />
                            <BillsHistory billingData={billingData} />
                            <PageFooter />
                        </AccordionContext.Provider>
                    </DataProvider>
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default Bills;
